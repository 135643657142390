@import "~@clientio/rappid/rappid.css";

.canvas-header {
  display: flex;
  justify-content: space-between;
  z-index: 99;
  text-align: left;
  padding: 12px 20px;
  position: fixed;
  background-color: #fff;
  left: 160px;
  right: 0;
  border: 1px #0000001F;
  border-bottom-style: double;
}

.canvas {
  width: 100%;
  height: 100%;
  margin-left: -2px;
  position: relative;
  padding-top: 61px;
  background: #fafafa;
}

.joint-paper {
  border: 1px solid #DCDCDC;
  margin-left: -1px;
}

.joint-type-workflowpart.highlight-connecting {
  rect {
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.3);
    stroke-dasharray: 5 5;
  }
}

foreignObject {
  text-align: left;

  p {
    margin: 0
  }
}

.is-ghost {
  pointer-events: none;
}

.part-main, .ticket-main, .lane-main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.link-main {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.link-titles {
  background-color: #FFF;
  display: flex;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  padding: 4px 8px;

  &.condition-link {
    background-color: #000;
    color: #fff;
  }
}

.lane-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 328px;
  overflow: hidden;
}

.part-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
}

.part-metadata, .ticket-metadata {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  color: #0000008F
}

.ticket-main {
  font-size: 11px;
  width: 80px;

  .ticket-name {
    pointer-events: none;
  }

  .ticket-add {
    font-size: 20px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    text-align: center;
    width: 100%;
    pointer-events: none;
    display: none;
  }

  &:hover {
    .ticket-add {
      display: block;
    }
  }
}


.metadata-icon {
  padding-left: 20px;
  background-repeat: no-repeat;
  background-position: 0 center;
  line-height: 16px;
  display: inline-block;

  + .metadata-icon {
    margin-left: 5px;
  }

  &.icon-none {
    padding-left: 0;
  }
}

.icon-system {
  background-image: url("./icons/DnsOutlined.svg")
}

.icon-tel {
  background-image: url("./icons/PhoneinTalkOutlined.svg")
}

.icon-email {
  background-image: url("./icons/EmailOutlinedIcon.svg")
}

.icon-fax {
  background-image: url("./icons/FaxOutlinedIcon.svg")
}

.icon-excel {
  background-image: url("./icons/TableChartOutlinedIcon.svg")
}

.icon-error {
  padding-left: 14px;
  background-image: url("./icons/ErrorOutlined.svg")
}

.icon-comment {
  padding-left: 16px;
  width: 12px;
  background-image: url("./icons/SmsOutlined.svg");
  background-size: cover;
}

.icon-document {
  padding-left: 16px;
  background-image: url("./icons/ArticleOutlined.svg")
}

.icon-none {
  background-image: none;
}

.joint-context-toolbar {

  .tools {
    display: flex;
    padding: 8px 0;
    width: 100px;
    flex-direction: column;
    align-items: flex-start;
    transform: translate(50%, 0);
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    .tool {
      border: none !important;
      padding-left: 10px;
      text-align: left;
    }
  }
}

.label-rotation {
  transform-box: fill-box;
  transform-origin: center;
  transform: rotate(90deg);
}
