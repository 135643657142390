html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#container {
  text-align: center;
  margin: 55px 0 0 160px;
  height: calc(100vh - 55px);
  min-height: calc(100vh - 55px);
}

#container_default {
  text-align: center;
  margin: 0 0 0 55px;
  height: 100vh;
  background: #FAFAFA;
}

.align-content-center {
  align-content: center;
}

.bg-color-gray {
  background: #F3F4F6;
}

.bg-color-gray-dark {
  background: #BDBDBD;
}

.bg-color-white {
  background: white;
}

.center-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

.flex {
  display: flex;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-700 {
  font-weight: 700;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-24 {
  font-size: 24px;
}

.justify-content-between {
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-red {
  color: red;
}

.ml-20 {
  margin-left: 20px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.max-w-522 {
  max-width: 522px;
}

.p-10 {
  padding: 10px;
}

.position-relative {
  position: relative;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.w-167 {
  width: 167px;
}

.w-258 {
  width: 258px;
}

