.editor {
    cursor: text;
    padding: 3px;
    text-align: initial;
    scale: 1;
    overflow-y: auto;
    max-height: 200px;
}

.editor :global(.public-DraftEditor-content) {
    min-height: 30px;
    max-height: 200px;
}